import React from 'react'
import styled, { css } from "styled-components";
import { ThemeContext } from "../../context/ThemeProvider";

const Icons = ({ type, valid }) => {
    const { actTheme } = React.useContext(ThemeContext);

    const [color, setColor] = React.useState("#BDBDBD")

    React.useEffect(() => {
        switch (valid) {
            case null:
                setColor("#BDBDBD")
                break;
            case "focused":
                setColor(actTheme === "light" ? "black" : "white")
                break;
            case "true":
                setColor(actTheme === "light" ? "black" : "white")
                break;
            case "false":
                setColor("#EB5757")
                break;
            default:
                setColor(actTheme === "light" ? "black" : "white")
                break;
        }
    }, [valid, actTheme])

    const colors = {
        border: {
            base: color,
            succes: "#28a745",
            error: "#EB5757",
            focus: "#000000",
        },
    
        warning: {
            text: "#C7C7C7",
        },
    };
    
    const Icon = styled.svg`
        position: absolute;
        font-size: 24px;
        left: 17px;
        bottom: 50%;
        top: 50%;
        transform: translateY(-50%);
        color: ${colors.border.base};
        :focus {
            color: ${colors.border.base};
        }
        [data-theme="dark"] & {
            color: #fff;
        }
        ${(props) =>
            props.valid === "true" &&
            css`
                color: ${colors.border.base};
            `}
    
        ${(props) =>
            props.valid === "false" &&
            css`
                color: ${colors.border.error} !important;
            `}
    `;

    return (
            type === "name" ?
            <Icon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </Icon> :
            type === "phone" ? 
            <Icon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17 2H7C5.89543 2 5 2.89543 5 4V20C5 21.1046 5.89543 22 7 22H17C18.1046 22 19 21.1046 19 20V4C19 2.89543 18.1046 2 17 2Z" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12 16H12.01" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </Icon> :
            <Icon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 4H20C21.1 4 22 4.9 22 6V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18V6C2 4.9 2.9 4 4 4Z" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M22 6L12 13L2 6" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </Icon> 
    )
}

export default Icons