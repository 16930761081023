import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Icons from "./Icons"
import Ricardo from "./../../assets/img/contact/Ricardo contact.svg";

const colors = {
    border: {
        base: "#BDBDBD",
        succes: "#28a745",
        error: "#EB5757",
        focus: "#000000",
    },

    warning: {
        text: "#C7C7C7",
    },
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    padding: 60px 0;
    transition: all 1.2s ease-in-out;
    [data-theme="dark"] & {
        background: #202534;
    }
    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }
    h2{
        font-family: Product;
        font-style: normal;
        font-weight: bold;
        font-size: 48px;
        text-align: center;
        letter-spacing: 0.03em;
        background: -webkit-linear-gradient(135deg, #5a2682 -10.45%, #462a92 24.53%, #2a2fa9 76.78%);
        transition: all 1.2s ease-in-out;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        [data-theme="dark"] & {
            background: -webkit-linear-gradient(135deg, #fff -10.45%, #fff 24.53%, #fff 76.78%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
        @media (max-width: 768px) {
            font-size: 1.1em;
            margin-bottom: 40px;
        }
    }
`;

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    @media (max-width: 768px) {
        flex-direction: column-reverse;
    }
`;

const Left = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 50%;
    margin: 0 32px 0 0;
    @media (max-width: 768px) {
        width: 100%;
        margin: 0 auto;
        justify-content: center;
    }
`;

const Formulario = styled.form`
    position: relative;
    width: fit-content;
    display: grid;
    grid-template-columns: 1fr;
    padding: 40px;
    padding-top: 30px;
    background: #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 2px 19px rgba(108, 108, 108, 0.2);
    border-radius: 8px;
    min-height: 531px;
    transition: all 1.2s ease-in-out;
    [data-theme="dark"] & {
        background: #171b2c;
        border: 1px solid #00000080;
        box-shadow: 0px 8px 8px  rgba(0, 0, 0, 0.25);
    }
    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        width: 90%;
        box-sizing: border-box;
        padding: 15px;
    }
    @media (min-width: 768px) and (max-width: 1023px) {
        margin-top: 50px;
        width: 60%;
        padding: 40px;
    }
    div .btn-contact {
        @media (max-width: 768px) {
            & {
                width: 100%;
            }
        }
    }
`;

const InputGroup = styled.div`
    position: relative;
    height: 48px;
    width: 100%;
    min-width: 330px;
`;

const HtmlInput = styled.input`
    position: absolute;
    width: 100%;
    background: transparent;
    border-radius: 4px;
    height: 48px;
    line-height: 48px;
    padding: 0 38px 0 58px;
    transition: all 0.2s ease-in-out;
    border: 1px solid ${colors.border.base};
    box-sizing: border-box;
    font-family: Roboto;
    font-weight: normal;
    font-size: 16px;
    letter-spacing: 0.15px;
    [data-theme="dark"] & {
        color: white;
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    &[type="number"] {
        -moz-appearance: textfield;
    }

    ${(props) =>
        props.valid === "true" &&
        css`
            border: 1px solid ${colors.border.base};
        `}
    ${(props) =>
        props.valid === "false" &&
        css`
            border: 2px solid ${colors.border.error} !important;
        `}
`;

const Warning = styled.p`
    font-family: Roboto;
    font-weight: normal;
    font-size: 12px;
    color: #c7c7c7;
    opacity: 0;
    margin-bottom: 0;
    margin-top: 2px;
    height: 28px;
    ${(props) =>
        props.valid === "true" &&
        css`
            opacity: 0;
        `}
    ${(props) =>
        props.valid === "false" &&
        css`
            opacity: 1;
        `}
    ul{
        margin: 0;
    }
`;

const Icon = styled(Icons)`
    position: absolute;
    font-size: 24px;
    left: 17px;
    bottom: 50%;
    top: 50%;
    transform: translateY(-50%);
    color: ${colors.border.base};
    :focus {
        color: ${colors.border.focus};
    }
    [data-theme="dark"] & {
        color: #fff;
    }
    ${(props) =>
        props.valid === "true" &&
        css`
            color: ${colors.border.base};
        `}

    ${(props) =>
        props.valid === "false" &&
        css`
            color: ${colors.border.error} !important;
        `}
        ${(props) =>
        props.valid === "true" &&
        css`
            color: ${colors.border.focus};
        `}

    ${(props) =>
        props.valid === null &&
        css`
            color: ${colors.border.base} !important;
        `}
`;

const Success = styled.svg`
    position: absolute;
    font-size: 24px;
    right: 10px;
    bottom: 50%;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    ${(props) =>
        props.valid === "true" &&
        css`
            opacity: 1;
        `}
    ${(props) =>
        props.valid === "false" &&
        css`
            opacity: 0;
        `}
`;

const Label = styled.label`
    font-family: Roboto;
    font-size: 16px;
    color: ${colors.border.base};
    [data-theme="dark"] & {
        color: #fff;
    }
`;

const Dropdown = styled.div`
    position: relative;
    background: transparent;
    height: 48px;
    border-radius: 4px;
    padding: 0 38px 0 48px;
    border: 1px solid ${colors.border.base};
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.15px;
    line-height: 48px;
    color: #2a2fab;
    box-sizing: border-box;
    transition: all 0.2s ease-in-out;
    z-index: 80;
    [data-theme="dark"] & {
        border: 1px solid #fff;
        color: white;
    }
    div{
        cursor: pointer;
        z-index: 60;
        p{
            margin: 0;
        }
        .fa-arrow {
            position: absolute !important;
            font-size: 24px !important;
            left: unset !important;
            right: 10px !important;
            bottom: 50% !important;
            top: 50% !important;
            transform: translateY(-50%) !important;
            cursor: pointer;
        }
    }
`;

const Options = styled.div`
    display: none;
    position: absolute;
    width: 100%;
    background: white;
    flex-direction: column;
    /* gap: 15px; */
    padding: 15px 0 15px 15px;
    border-radius: 4px;
    border: 1px solid ${colors.border.base};
    box-sizing: border-box;
    animation: slideDown 1s forwards;
    z-index: 90;
    [data-theme="dark"] & {
        border: 1px solid #fff;
        background: #202534;
    }
    label {
        display: flex;
        align-items: center;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        letter-spacing: 0.15px;
        color: #747888;
        margin-top: 8px;
        margin-bottom: 8px;
        cursor: pointer;
        input[type="radio"],
        input[type="checkbox"] {
            width: 20px;
            height: 20px;
            background-color: transparent;
            border: 2px solid #263238;
            margin: 0;
            margin-right: 20px;
        }
    }
    &.show{
        display: flex;
    }
`;

const Problem = styled.div`
    position: relative;
    border-radius: 4px;
    border: 1px solid ${colors.border.base};
    box-sizing: border-box;
    grid-column-start: 1;
    grid-column-end: 3;
    box-sizing: border-box;
    padding: 15px 38px 0 50px;
    [data-theme="dark"] & {
        border: 1px solid #fff;
    }
    @media (max-width: 764px) {
        grid-column-start: unset;
        grid-column-end: unset;
    }
    textarea {
        border: none;
        outline: none;
        width: 100%;
        font-family: Roboto;
        font-weight: normal;
        font-size: 16px;
        letter-spacing: 0.15px;
        resize: none;
        [data-theme="dark"] & {
            background: #202534;
            color: white;
        }
    }
    &:focus {
        border: 2px solid #77216b;
        outline: none;
    }
    svg {
        position: absolute;
        font-size: 24px;
        left: 18px;
        top: 15px;
        bottom: unset;
        transform: unset;
    }
`;

const Director = styled.div`
    width: 50%;
    margin: 0 0 0 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: 920px) {
        margin: 0 auto 30px auto;
        width: 85%;
        align-items: center;
    }
`;

const Parcial = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 500px;
    margin: 0;
    @media (max-width: 920px) {
        margin-bottom: 30px;
        align-items: center;
    }
`;

const Picture = styled.div`
    background-image: url("${Ricardo}");
    background-size: contain;
    background-repeat: no-repeat;
    width: 161px;
    height: 159px;
    @media (max-width: 764px) {
        width: 182px;
        height: 182px;
        margin-bottom: 20px;
    }
`;

const Name = styled.p`
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #77216b;
    margin: 41px 0 0 0;
    transition: all 1.2s ease-in-out;
    [data-theme="dark"] & {
        color: #fff;
    }
`;

const Charge = styled.p`
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #77216b;
    margin: 0;
    transition: all 1.2s ease-in-out;
    [data-theme="dark"] & {
        color: #fff;
    }
`;

const Social = styled.div`
    display: flex;
    /* gap: 25px; */
    font-size: 25px;
    text-align: flex-start;
    color: #2a2fa9;
    margin: 20px 0 0 0;
    transition: all 1.2s ease-in-out;
    a {
        color: #2a2fa9;
        [data-theme="dark"] & {
            color: #fff;
        }
        i {
            margin-right: 26px;
            transition: all 0.1s ease-in-out;
            @media (max-width: 764px) {
                margin-left: 13px;
                margin-right: 13px;
                text-align: center; 
            }
            :hover {
                transform: scale(1.05);
            }
        }
    }
`;

const Career = styled.p`
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-align: left;
    letter-spacing: 0.01em;
    color: #2a2fa9;
    transition: all 1.2s ease-in-out;
    [data-theme="dark"] & {
        color: #fff;
    }
    @media (max-width: 920px) {
        text-align: center; 
    }
`;

const Legend = styled.p`
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: left;
    letter-spacing: 0.01em;
    color: #2a2fa9;
    transition: all 1.2s ease-in-out;
    [data-theme="dark"] & {
        color: #fff;
    }
    @media (max-width: 920px) {
        text-align: center; 
    }
`;

const FormTitle = styled.h3`
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    color: #192A3E;
    margin: 0;
    [data-theme="dark"] & {
        color: #fff;
    }
`;

const FormLogo = styled.div`
    position: absolute;
    bottom: 35px;
    left: 40px;
`;

export {
    Container,
    Formulario,
    Icon,
    InputGroup,
    HtmlInput,
    Warning,
    Success,
    Label,
    Dropdown,
    Options,
    Problem,
    Director,
    Picture,
    Name,
    Charge,
    Social,
    Career,
    Legend,
    Parcial,
    Wrapper,
    Left,
    FormTitle,
    FormLogo
};
