import React from "react";
import "./login.scss";

const Login = ({ open, close, signIn, user, auth, photoURL, logged, uid }) => {
    return open ? (
        <div className="modal-container">
            <div className="modal-card-container">
                <i className="fas fa-times close" onClick={close}></i>
                {user ? (
                    <>
                        <img src={photoURL} alt={uid} />
                        <i
                            className={`fas fa-sign-out-alt button ${logged}`}
                            onClick={() => auth.signOut()}
                        ></i>
                    </>
                ) : (
                    <i className="fab fa-google button" onClick={signIn}></i>
                )}
            </div>
        </div>
    ) : null;
};

export default Login;
