import React from "react";
import styled from "styled-components";

const Bubble = () => {
  return (
    <All
      href="https://api.whatsapp.com/send?phone=++528712778426&text=Hola%20Ingenier%C3%ADa%20Digital,%20quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20sus%20servicios.%20M%C3%AD%20nombre%20es:%20"
      target="_blank"
    >
      <Bub>
        <i className="fab fa-whatsapp"></i>
      </Bub>
      <Platform>
        <svg
          width="45"
          height="8"
          viewBox="0 0 45 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.7"
            d="M44.5 4C44.5 4.07126 44.4608 4.21509 44.215 4.42869C43.9721 4.63971 43.5845 4.8664 43.0381 5.09604C41.9495 5.55357 40.3439 5.97676 38.3224 6.33615C34.2875 7.05347 28.6931 7.5 22.5 7.5C16.3069 7.5 10.7125 7.05347 6.67761 6.33615C4.65608 5.97676 3.05045 5.55357 1.9619 5.09604C1.41554 4.8664 1.02794 4.63971 0.78504 4.42869C0.539173 4.21509 0.5 4.07126 0.5 4C0.5 3.92874 0.539173 3.78491 0.78504 3.57131C1.02794 3.36029 1.41554 3.1336 1.9619 2.90396C3.05045 2.44643 4.65608 2.02324 6.67761 1.66385C10.7125 0.946535 16.3069 0.5 22.5 0.5C28.6931 0.5 34.2875 0.946535 38.3224 1.66385C40.3439 2.02324 41.9495 2.44643 43.0381 2.90396C43.5845 3.1336 43.9721 3.36029 44.215 3.57131C44.4608 3.78491 44.5 3.92874 44.5 4Z"
            stroke="#77216B"
          />
        </svg>
        <svg
          width="31"
          height="6"
          viewBox="0 0 31 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.5"
            d="M30.5 3C30.5 3.01907 30.4921 3.09893 30.3415 3.24135C30.1909 3.38379 29.9412 3.54498 29.5739 3.71308C28.8422 4.0479 27.7518 4.36204 26.3651 4.63043C23.6 5.16562 19.7583 5.5 15.5 5.5C11.2417 5.5 7.40001 5.16562 4.63486 4.63043C3.24816 4.36204 2.15781 4.0479 1.42612 3.71308C1.05877 3.54498 0.809075 3.38379 0.658486 3.24135C0.507912 3.09893 0.5 3.01907 0.5 3C0.5 2.98093 0.507912 2.90107 0.658486 2.75865C0.809075 2.61621 1.05877 2.45502 1.42612 2.28692C2.15781 1.9521 3.24816 1.63796 4.63486 1.36957C7.40001 0.834378 11.2417 0.5 15.5 0.5C19.7583 0.5 23.6 0.834378 26.3651 1.36957C27.7518 1.63796 28.8422 1.9521 29.5739 2.28692C29.9412 2.45502 30.1909 2.61621 30.3415 2.75865C30.4921 2.90107 30.5 2.98093 30.5 3Z"
            stroke="#77216B"
          />
        </svg>
        <svg
          width="17"
          height="4"
          viewBox="0 0 17 4"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.3"
            d="M16.089 1.65845C16.4383 1.85278 16.4908 1.9796 16.4986 2C16.4908 2.02041 16.4383 2.14722 16.089 2.34155C15.7168 2.54856 15.1452 2.75119 14.3959 2.92751C12.9052 3.27827 10.8204 3.5 8.5 3.5C6.1796 3.5 4.09485 3.27827 2.60411 2.92751C1.85477 2.75119 1.28316 2.54856 0.911041 2.34155C0.561714 2.14722 0.509241 2.0204 0.50138 2C0.509241 1.9796 0.561714 1.85278 0.911041 1.65845C1.28316 1.45144 1.85477 1.24881 2.60411 1.07249C4.09485 0.721734 6.1796 0.5 8.5 0.5C10.8204 0.5 12.9052 0.721734 14.3959 1.07249C15.1452 1.24881 15.7168 1.45144 16.089 1.65845Z"
            stroke="#77216B"
          />
        </svg>
      </Platform>
    </All>
  );
};

const Bub = styled.div`
  position: fixed;
  bottom: 37px;
  right: 15px;
  height: 56px;
  width: 56px;
  border-radius: 50%;
  background: #2a2fab;
  z-index: 200;
  transition: all 0.3s ease-in-out;
  i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 28px;
    line-height: 28px;
    @media (max-width: 768px) {
      & {
        font-size: 20px;
        line-height: 20px;
      }
    }
  }
  @media (max-width: 768px) {
    & {
      height: 48px;
      width: 48px;
    }
  }
`;

const Platform = styled.div`
  position: fixed;
  bottom: 5px;
  right: 15px;
  height: 27px;
  width: 56px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 200;
  transition: all 0.3s ease-in-out;
  @media (max-width: 768px) {
    & {
      bottom: 15px;
      height: 19px;
      width: 48px;
    }
  }
`;

const All = styled.a`
  &:hover {
    ${Bub} {
      transform: scale(1.05) translateY(-4px);
    }
    ${Platform} {
      height: 29px;
      svg:nth-child(1) {
        transform: scale(1.05);
      }
      svg:nth-child(2) {
        transform: scale(1.03);
      }
    }
  }
`;

export default Bubble;
