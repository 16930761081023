import React from "react";
import "./teamcard.scss";

const Teamcard = ({ name, charge, picture, id }) => {
    return (
        <div className="card-container">
            <div className="teamcard" key={id}>
                <div className="info">
                    <div className="name">{name}</div>
                    <div className="charge">{charge}</div>
                </div>
                <div className="picture">
                    <img src={picture} alt={name} />
                </div>
            </div>
        </div>
    );
};

export default Teamcard;
