import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/analytics";
import "firebase/storage";

import { useAuthState } from "react-firebase-hooks/auth";
import { useCollectionData } from "react-firebase-hooks/firestore";

firebase.initializeApp({
    apiKey: "AIzaSyDD1D9ae04m1HrKInX-8n2T7OEUK-55E6s",
    authDomain: "id-test-0.firebaseapp.com",
    projectId: "id-test-0",
    storageBucket: "id-test-0.appspot.com",
    messagingSenderId: "738920540965",
    appId: "1:738920540965:web:09ff65eacd5a1d9a2e88cc",
});

const auth = firebase.auth();
const firestore = firebase.firestore();

export { firebase };
