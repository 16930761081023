import React, { useContext } from "react";
import { LangContext } from "../../context/LangProvider";
import Button from "../general/Button";
import figma from "../../assets/img/footer/figma.svg";
import Contentful from "../../assets/img/footer/Contentful.svg";
import gatsby from "../../assets/img/footer/gatsby.svg";
import react from "../../assets/img/footer/react.svg";
import mail from "../../assets/img/footer/mail.svg";
import phone from "../../assets/img/footer/phone.svg";
import logotipo from "../../assets/img/footer/Ingeniería Digital Rec 1.svg";
import instagram from "../../assets/img/footer/instagram.svg";
import linkedin from "../../assets/img/footer/linkedin.svg";
import pinterest from "../../assets/img/footer/pinterest.svg";

import "./footer.scss";

const Footer = () => {
  const { fileTexts } = useContext(LangContext);

  return (
    <footer className="container-footer">
      <div className="organization">
        <div className="prevfo logotype">
          <img classname="" src={logotipo} alt={"Ingenieria Digital"} />
          <div className="social">
            <a href="https://youtube.com/channel/UC3YYnRbD5-YAJnZ0P2lx5Kw" target="_blank" rel="noreferrer">
              <i style={{ fontSize: '22px', marginRight: '3px' }} className="fab fa-youtube"></i>
            </a>
            <a href="https://www.instagram.com/ingenieriadigital.mx/">
              <img src={instagram} alt={"Instagram"} />
            </a>
            <a href="https://www.linkedin.com/company/ingenieriadigital/">
              {" "}
              <img src={linkedin} alt={"Linkedin"} />
            </a>
            <a href="https://www.pinterest.com.mx/ingenieriadigitalmx/p%C3%A1ginas-web-ingenier%C3%ADa-digital/">
              {" "}
              <img src={pinterest} alt={"Pinterest"} />
            </a>
          </div>
        </div>

        <div className="prevfo talent">
          <p className="talentostext">{fileTexts.footer.talent} </p>
          <a href="mailto: contacto@ingenieriadigital.mx">
            <Button
              text={fileTexts.footer.button}
              classes="rounded-btn white-btnsecond footer-btn"
            />
          </a>
        </div>
        <div className="prevfo contact">
          <p className="talentostext2">
            <a href="mailto:contacto@ingenieriadigital.mx">
              <img className="margimg" src={mail} alt={"Mail"} />{" "}
              contacto@ingenieriadigital.mx
            </a>{" "}
          </p>
          <p>
            <a href="tel:8712778426">
              {" "}
              <img className="margimg" src={phone} alt={"Phone"} />
              +52 871 277 8426
            </a>{" "}
          </p>
        </div>
      </div>
      <div className="second-container-footer">
        <div className="prevfoo made">
          <div>
            <p className="light">
              {fileTexts.footer.made}
              <a
                href="https://assets.ctfassets.net/lcgg8qdtveu1/bfwuSaPQVPIygVfuD13KM/43ea0235e57a5c69219202913b39637c/Aviso_Privacidad_-_Ingenieri__a_Digital_V1.pdf"
                className="bold"
              >
                <strong> {fileTexts.footer.advice}</strong>
              </a>
            </p>{" "}
          </div>
          <div>
            <p className="light">
              <a
                href="https://assets.ctfassets.net/lcgg8qdtveu1/bfwuSaPQVPIygVfuD13KM/43ea0235e57a5c69219202913b39637c/Aviso_Privacidad_-_Ingenieri__a_Digital_V1.pdf"
                className="bold2"
              >
                <strong> {fileTexts.footer.advice}</strong>
              </a>
            </p>{" "}
          </div>
        </div>

        <div className="prevfoo artesanal">
          <p>
            {fileTexts.footer.madeart}
            <img src={figma} alt={"Figma"} /> +{" "}
            <img src={Contentful} alt={"Contentful"} /> +{" "}
            <img src={gatsby} alt={"Gatsby"} /> +{" "}
            <img src={react} alt={"React"} />
          </p>
        </div>
      </div>
      <div className="lastone">
        <div className="prevfooo artesanal2">
          <p>{fileTexts.footer.madeart}</p>
          <p>
            {" "}
            <img src={figma} alt={"Figma"} /> +{" "}
            <img src={Contentful} alt={"Contentful"} /> +{" "}
            <img src={gatsby} alt={"Gatsby"} /> +{" "}
            <img src={react} alt={"React"} />
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
