import React, { useState, useEffect, useRef } from "react";
import { Redirect } from "react-router-dom";
import {
    Container,
    Formulario,
    InputGroup,
    Warning,
    Success,
    Label,
    Director,
    Picture,
    Name,
    Charge,
    Social,
    Career,
    Legend,
    Parcial,
    Wrapper,
    Left,
    FormTitle,
    FormLogo
} from "./formularioStyle";
import {
    persona,
    mail,
    phone
} from "../../assets/icons";
import "./style.css";
import SL from "../../assets/img/contact/SLlogo.svg"
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
// import Button from "../general/Button";
import Input from "./Input";
import Reaptcha from "reaptcha";
import axios from 'axios'
import Button from '@material-ui/core/Button';
import { createTheme, withStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { dark, purple } from '@material-ui/core/colors';
import Icon from './Icons'
import { ThemeContext } from "../../context/ThemeProvider";

// import emailjs from "emailjs-com";
const Contact = ({ propRef }) => {
    const { actTheme } = React.useContext(ThemeContext);
    // function sendEmail(e) {
    //     e.preventDefault();
    //     if (
    //         inputName.valid === "true" &&
    //         inputPhone.valid === "true" &&
    //         inputMail.valid === "true" &&
    //         captchaStatus === true &&
    //         ap === true
    //     ) {
    //         emailjs
    //             .sendForm(
    //                 "service_g2tlnrx",
    //                 "template_rufrm8h",
    //                 e.target,
    //                 "user_EzBbpRZIL7XUoRBTMfE5r"
    //             )
    //             .then(
    //                 (result) => {
    //                     window.location.replace("https://ingenieriadigital.mx/gracias");
    //                     console.log(result.text);
    //                 },
    //                 (error) => {
    //                     console.log(error.text);
    //                 }
    //             );
    //         //e.target.reset();
    //     }
    // }

    const onSubmitHandler = async (e) => {
        e.preventDefault();
        let res = await axios.get(`https://vdq03wz1t2.execute-api.us-east-1.amazonaws.com/prod/clientiyu?clte_id=2&clte_name=${inputName.field}&clte_phone=+${inputPhone.field}&clte_pass=69101&clte_lastname=" "`)
        try {
            console.log(res.data);
        } catch (error) {
            console.log(error);
        }
        let ipinfo = await axios.get('https://ipinfo.io/json')
    }

    const [inputName, setInputName] = useState({ field: "", valid: null, error: ['Revisa que tu nombre esté completo.'] });
    const [inputPhone, setInputPhone] = useState({ field: "", valid: null, error: ['Verifica número de contacto.'] });
    const [inputMail, setInputMail] = useState({ field: "", valid: null, error: ['Verificar que el correo esté correcto.'] });

    const [ap, setAp] = useState(true);

    const [btnStatus, setBtnStatus] = useState(true);

    const [captchaStatus, setCaptchaStatus] = useState(false);

    const [country, setCountry] = useState("")

    useEffect(() => {
        const getCountry = async () => {
            let res = await axios.get("https://ipinfo.io/json?token=d9628d6181c129")
            try {
                console.log(res);
                setCountry(res.data.country.toLowerCase())
            } catch (error) {
                
            }
        }
        getCountry()
    }, [])

    const expretions = {
        //usuario: /^[a-zA-Z0-9\_\-]{4,16}$/, // Letras, numeros, guion y guion_bajo
        name: /^[a-zA-ZÀ-ÿ\s]{2,40}$/, // Letras y espacios, pueden llevar acentos.
        web: /^((https?|ftp|smtp):\/\/)?(www.)?[a-zA-Z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/, // Letras y espacios, pueden llevar acentos.
        //password: /^.{4,12}$/, // 4 a 12 digitos.
        mail: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
        phone: /^\d{11,15}$/,
        phone2: /^[+]\d{1,4}\s[(?]\d+[)?]\s\d/,
        sells: /^\d{0,20}$/, // 0 a 20 numeros.
        city: /^[a-zA-ZÀ-ÿ\s]{2,40}$/, // Letras y espacios, pueden llevar acentos.
        //comment: /^[a-zA-ZÀ-ÿ\s_]{2,150}$/, // Letras y espacios, pueden llevar acentos.
    };
    
    const onVerify = (response) => {
        setCaptchaStatus(response ? true : false);
    };
    
    const lookup = async (value) => {
        try {
            let res = await axios.get(`https://intl-tel-input-1497.twil.io/lookup?phone=+${value}`, {
                validateStatus:  status => {
                  return status < 500; // Resolve only if the status code is less than 500
                }
              })
            if(res.data.success){
                setInputPhone({ ...inputPhone, valid: "true" })
            }else{
                setInputPhone({ ...inputPhone, valid: "false" })
            }
            console.log(res);
        } catch (error) {
            console.log(error);
        }

    }

    const onChangeHandler = (value) => {
        setInputPhone({ ...inputPhone, field: value });
        lookup(value)
    };

    useEffect(() => {
        if (
            inputName.valid === "true" &&
            inputPhone.valid === "true" &&
            inputMail.valid === "true" &&
            captchaStatus === true &&
            ap === true
        ) {
            setBtnStatus(false);
        } else {
            setBtnStatus(true);
        }
    }, [inputName.valid, inputPhone.valid, inputMail.valid, captchaStatus, ap]);

    // Close the dropdown if the user clicks outside of it
    window.onclick = function(event) {
        if (!event.target.matches('.dropbtn')) {
            let dropdowns = document.getElementsByClassName("dropdown-content");
            let i;
            for (i = 0; i < dropdowns.length; i++) {
                let openDropdown = dropdowns[i];
                if (openDropdown.classList.contains('show')) {
                openDropdown.classList.remove('show');
                console.log("removed class");
                }
            }
        }
    }

    const ColorButton = withStyles({
        root: {
          fontSize: 16,
          padding: '6px 24px',
          lineHeight: 1.5,
          fontFamily: 'Roboto',
          border: '1px solid #90A0B7',
          borderRadius: 4,
          backgroundColor: 'white',
          color: '#192A3E',
          transition: 'all .4s ease-in-out',
          boxShadow: 'none',
          '&:hover': {
            backgroundColor: actTheme === "light" ? '#192A3E' : "white",
            color: actTheme === "light" ? 'white' : "black",
            borderColor: '#192A3E',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            transform: 'translateY(-4px)',
          },
          '&:disabled': {
            backgroundColor: actTheme === "dark" ? 'gray' : 'default',
            color: actTheme === "dark" ? 'white' : 'default'
          },
          '&:active': {
            boxShadow: 'none',
            transform: 'translateY(0px)'
          },
          '&:focus': {
            boxShadow: '0 0 0 0.2rem rgba(0, 0, 0, 0.25)',
          },
        },
      })(Button);

    return (
        <Container ref={propRef}>
            <h2>Atención inmediata</h2>
            <Wrapper>
                <Left>
                    <Formulario onSubmit={onSubmitHandler}>
                        <FormTitle>Atención Express</FormTitle>
                        <Input
                            state={inputName}
                            setState={setInputName}
                            icon={persona}
                            type="text"
                            placeholder="Tu nombre"
                            regex={expretions.name}
                            name="name"
                            id="nombre"
                        />
                        <div>
                            <InputGroup>
                                <PhoneInput
                                    id="telefono"
                                    country={country}
                                    searchPlaceholder="Buscar"
                                    searchNotFound="No se encontraron resultados"
                                    placeholder="¿A qué número te hablamos?"
                                    enableSearch={true}
                                    inputProps={{
                                        name: "phone",
                                    }}
                                    inputClass={inputPhone.valid === "true" ? "valid" : ""}
                                    buttonStyle={{
                                        paddingLeft: "35px",
                                        transform: "scale(.7)",
                                    }}
                                    dropdownStyle={{
                                        transform: "scale(1.2)",
                                        zIndex: "100",
                                    }}
                                    containerStyle={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                    onChange={onChangeHandler}
                                    isValid={inputPhone.valid === "true" ? true : inputPhone.valid === "false" ? false : undefined}
                                    onFocus={() => inputPhone.field === "" ? setInputPhone({ ...inputPhone, valid: "focused" }): null}
                                />
                                <Icon type="phone" valid={inputPhone.valid} />
                                <Success
                                    id="input-success"
                                    valid={inputPhone.valid}
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18457 2.99721 7.13633 4.39828 5.49707C5.79935 3.85782 7.69279 2.71538 9.79619 2.24015C11.8996 1.76491 14.1003 1.98234 16.07 2.86"
                                        stroke="#1DB954"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M22 4L12 14.01L9 11.01"
                                        stroke="#1DB954"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </Success>
                            </InputGroup>
                            <Warning id="phone-warning" valid={inputPhone.valid}> 
                                <ul>
                                    {inputPhone.error.map( e => (<li>{e}</li>))}
                                </ul>
                            </Warning>
                        </div>
                        <Input
                            state={inputMail}
                            setState={setInputMail}
                            icon={mail}
                            placeholder="Correo que más revisas"
                            regex={expretions.mail}
                            name="email"
                            id="correo"
                        />
                        <div className="start">
                            <Label>
                                <input
                                    type="checkbox"
                                    name="aviso"
                                    id="aviso"
                                    onChange={() => setAp(!ap)}
                                    defaultChecked={true}
                                />
                                Acepto el aviso de privacidad
                            </Label>
                        </div>
                        <Reaptcha
                            sitekey="6LdjJnwaAAAAACxjYSg_6AHERBCagkyEXik_5LDr"
                            onVerify={onVerify}
                            className="start"
                            badge="inline"
                        />
                        <div className="end">
                            {/* <Button
                                type="submit"
                                text="Enviar"
                                classes={`btn-contact ${
                                    btnStatus ? "disabled-btn" : ""
                                } `}
                                style={{
                                    padding: "11px 55px",
                                    fontSize: "16px",
                                }}
                                disabled={btnStatus}
                            /> */}
                            <ColorButton type="submit" variant="contained" disabled={btnStatus}>Enviar</ColorButton>
                        </div>
                        <FormLogo>
                            <a href="https://ingenieriadigital.mx">
                                <img src={SL} />
                            </a>
                        </FormLogo>
                    </Formulario>
                </Left>
                <Director>
                    <Parcial>
                        <Picture></Picture>
                        <Name>Ing. Ricardo López Reyero</Name>
                        <Charge>Director General</Charge>
                    </Parcial>
                    <Parcial>
                        <Career>Ingenierío Industrial y de sistemas del ITESM</Career>
                        <Legend>
                            Vivo inmerso en un mundo digital, me apasiona encontrar los
                            mejores proveedores y formar los mejores equipos de trabajo
                            posibles.
                        </Legend>
                        <Social>
                            <a href="https://instagram.com/ricardolopezreyero?igshid=av3lz91mdmeo">
                                <i className="fab fa-instagram"></i>
                            </a>
                            <a href="https://www.linkedin.com/in/ricardo-lopez-reyero">
                                <i className="fab fa-linkedin"></i>
                            </a>
                            <a href="https://youtube.com/channel/UC3YYnRbD5-YAJnZ0P2lx5Kw">
                                <i className="fab fa-youtube"></i>
                            </a>
                        </Social>
                    </Parcial>
                </Director>
            </Wrapper>
        </Container>
    );
};

export default Contact;
