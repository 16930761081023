import React from 'react'
import Background from './../../assets/img/Proposito Background.png'
import styled from 'styled-components'
import Button from '../general/Button'
import download from 'downloadjs'
import brochure from './../../assets/Ingeniería Digital - Brochure 2020.pdf'

const Purpose = () => {
    const descargar = () => {
        download(brochure)
    }
    return (
        <Container>
            <img src={Background} alt="Our Purpose" />
            <Content>
                <h2>El propósito de Ingeniería Digital es ayudar a tantas empresas  como nos sea posible a crecer y lo hacemos incrementando sus ventas.
                </h2>
                <Button classes={"rounded-btn white-btnsecond"} text={"Descargar Brochure"} onClick={descargar}/>
            </Content>
        </Container>
    )
}

const Content = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    h2{
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 28px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.03em;
        color: #FFFFFF;
        width: 63%;
        margin: 0 auto 32px auto;
        @media (max-width: 768px) {
            font-size: 20px;
            width: 80%;
        }
    }
    button{
        max-width: 260px;
        margin: 0 auto;
    }
`;

const Container = styled.div`
    position: relative;
    img{
        width: 100%;
        height: 100%;
        @media (max-width: 768px) {
            height: 100%;
            width: unset;
        }
    }
`;

export default Purpose
