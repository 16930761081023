import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Icons from './Icons'
import Ricardo from "./../../assets/img/contact/Ricardo contact.svg";
import Email from 'react-autocomplete-email';

const colors = {
    border: {
        base: "#BDBDBD",
        succes: "#28a745",
        error: "#EB5757",
        focus:  (props) =>
            props.theme === "light" ? "#000000" : "white",
    },

    warning: {
        text: "#C7C7C7",
    },
};

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding: 60px 0;
    transition: all 1.2s ease-in-out;
    [data-theme="dark"] & {
        background: #202534;
    }
    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }
`;

const Formulario = styled.form`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    width: 50%;
    /* min-width: 675px; */
    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        width: 90%;
    }
    @media (min-width: 768px) and (max-width: 1024px) {
        margin-top: 50px;
    }

    div .btn-contact {
        @media (max-width: 768px) {
            & {
                width: 100%;
            }
        }
    }
`;

const InputGroup = styled.div`
    position: relative;
    height: 48px;
    width: 100%;
    min-width: 275px;
`;

const HtmlInput = styled.input`
    position: absolute;
    width: 100%;
    background: transparent;
    border-radius: 4px;
    height: 48px;
    line-height: 48px;
    padding: 0 38px 0 55px;
    transition: all 0.2s ease-in-out;
    border: 1px solid ${colors.border.base};
    box-sizing: border-box;
    font-family: Roboto;
    font-weight: normal;
    font-size: 16px;
    letter-spacing: 0.15px;
    [data-theme="dark"] & {
        color: white;
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    &[type="number"] {
        -moz-appearance: textfield;
    }

    ${(props) =>
        props.valid === "true" &&
        css`
            border: 1px solid ${colors.border.focus};
        `}
    ${(props) =>
        props.valid === "false" &&
        css`
            border: 2px solid ${colors.border.error} !important;
        `}
`;

const EmailInput = styled(Email)`
position: absolute;
width: 100%;
background: transparent;
border-radius: 4px;
height: 48px;
line-height: 48px;
padding: 0 38px 0 55px;
transition: all 0.2s ease-in-out;
border: 1px solid ${colors.border.base};
box-sizing: border-box;
font-family: Roboto;
font-weight: normal;
font-size: 16px;
letter-spacing: 0.15px;
[data-theme="dark"] & {
    color: white;
}
&::-webkit-outer-spin-button,
&::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
&[type="number"] {
    -moz-appearance: textfield;
}

${(props) =>
    props.valid === "true" &&
    css`
        border: 1px solid ${colors.border.focus};
    `}
${(props) =>
    props.valid === "false" &&
    css`
        border: 1px solid ${colors.border.error} !important;
    `}
`;

const Warning = styled.p`
    font-family: Roboto;
    font-weight: normal;
    font-size: 12px;
    color: #c7c7c7;
    opacity: 0;
    margin-bottom: 0;
    margin-top: 2px;
    height: 28px;
    ${(props) =>
        props.valid === "true" &&
        css`
            opacity: 0;
        `}
    ${(props) =>
        props.valid === "false" &&
        css`
            opacity: 1;
        `}
    ul{
        margin: 0;
    }
`;

const Icon = styled(Icons)`
    position: absolute;
    font-size: 24px;
    left: 17px;
    bottom: 50%;
    top: 50%;
    transform: translateY(-50%);
    color: ${colors.border.base};
    :focus {
        color: ${colors.border.focus};
    }
    [data-theme="dark"] & {
        color: #fff;
    }
    ${(props) =>
        props.valid === "true" &&
        css`
            color: ${colors.border.base};
        `}

    ${(props) =>
        props.valid === "false" &&
        css`
            color: ${colors.border.error} !important;
        `}
`;

const Success = styled.svg`
    position: absolute;
    font-size: 24px;
    right: 10px;
    bottom: 50%;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    ${(props) =>
        props.valid === "true" &&
        css`
            opacity: 1;
        `}
    ${(props) =>
        props.valid === "false" &&
        css`
            opacity: 0;
        `}
`;

const Loader = styled.div`
    position: absolute;
    right: 10px;
    bottom: 50%;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
`;

const Label = styled.label`
    font-family: Roboto;
    font-size: 16px;
    color: ${colors.border.base};
    [data-theme="dark"] & {
        color: #fff;
    }
`;

const Dropdown = styled.div`
    position: relative;
    background: transparent;
    height: 48px;
    border-radius: 4px;
    padding: 0 38px 0 48px;
    border: 1px solid ${colors.border.base};
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.15px;
    line-height: 48px;
    color: #2a2fab;
    box-sizing: border-box;
    transition: all 0.2s ease-in-out;
    z-index: 80;
    [data-theme="dark"] & {
        border: 1px solid #fff;
        color: white;
    }
    div{
        cursor: pointer;
        z-index: 60;
        p{
            margin: 0;
        }
        .fa-arrow {
            position: absolute !important;
            font-size: 24px !important;
            left: unset !important;
            right: 10px !important;
            bottom: 50% !important;
            top: 50% !important;
            transform: translateY(-50%) !important;
            cursor: pointer;
        }
    }
`;

const Options = styled.div`
    display: none;
    position: absolute;
    width: 100%;
    background: white;
    flex-direction: column;
    /* gap: 15px; */
    padding: 15px 0 15px 15px;
    border-radius: 4px;
    border: 1px solid ${colors.border.base};
    box-sizing: border-box;
    animation: slideDown 1s forwards;
    z-index: 90;
    [data-theme="dark"] & {
        border: 1px solid #fff;
        background: #202534;
    }
    label {
        display: flex;
        align-items: center;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        letter-spacing: 0.15px;
        color: #747888;
        margin-top: 8px;
        margin-bottom: 8px;
        cursor: pointer;
        input[type="radio"],
        input[type="checkbox"] {
            width: 20px;
            height: 20px;
            background-color: transparent;
            border: 2px solid #263238;
            margin: 0;
            margin-right: 20px;
        }
    }
    &.show{
        display: flex;
    }
`;

const Problem = styled.div`
    position: relative;
    border-radius: 4px;
    border: 1px solid ${colors.border.base};
    box-sizing: border-box;
    grid-column-start: 1;
    grid-column-end: 3;
    box-sizing: border-box;
    padding: 15px 38px 0 50px;
    [data-theme="dark"] & {
        border: 1px solid #fff;
    }
    @media (max-width: 764px) {
        grid-column-start: unset;
        grid-column-end: unset;
    }
    textarea {
        border: none;
        outline: none;
        width: 100%;
        font-family: Roboto;
        font-weight: normal;
        font-size: 16px;
        letter-spacing: 0.15px;
        resize: none;
        [data-theme="dark"] & {
            background: #202534;
            color: white;
        }
    }
    &:focus {
        border: 2px solid #77216b;
        outline: none;
    }
    svg {
        position: absolute;
        font-size: 24px;
        left: 18px;
        top: 15px;
        bottom: unset;
        transform: unset;
    }
`;

const Director = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 332px;
    @media (max-width: 764px) {
        margin-bottom: 30px;
    }
`;

const Picture = styled.div`
    background-image: url("${Ricardo}");
    background-size: contain;
    background-repeat: no-repeat;
    width: 332px;
    height: 329px;
    @media (max-width: 764px) {
        width: 182px;
        height: 182px;
        margin-bottom: 20px;
    }
`;

const Name = styled.p`
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #77216b;
    margin: 0;
    [data-theme="dark"] & {
        color: #fff;
    }
`;

const Charge = styled.p`
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #77216b;
    margin: 10px 0 0 0;
    [data-theme="dark"] & {
        color: #fff;
    }
`;

const Social = styled.div`
    display: flex;
    /* gap: 25px; */
    font-size: 25px;
    text-align: center;
    color: #2a2fa9;
    margin: 45px 0 30px 0;
    a {
        color: #2a2fa9;
        [data-theme="dark"] & {
            color: #fff;
        }
        i {
            margin-left: 13px;
            margin-right: 13px;
            transition: all 0.1s ease-in-out;
            :hover {
                transform: scale(1.05);
            }
        }
    }
`;

const Career = styled.p`
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #2a2fa9;
    [data-theme="dark"] & {
        color: #fff;
    }
`;

const Legend = styled.p`
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #2a2fa9;
    [data-theme="dark"] & {
        color: #fff;
    }
`;

export {
    Container,
    Formulario,
    Icon,
    InputGroup,
    HtmlInput,
    Warning,
    Success,
    Label,
    Dropdown,
    Options,
    Problem,
    Director,
    Picture,
    Name,
    Charge,
    Social,
    Career,
    Legend,
    EmailInput,
    Loader
};
