import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Button from "../general/Button";

const Thanks = () => {
    return (
        <ThanksContainer>
            <Logo />
            <Content>
                <Image />
                <Text>
                    ¡Muchas gracias! Estamos analizando la información y nos
                    pondremos en contacto lo antes posible.
                </Text>
                <Link to="/">
                    <Button
                        type="button"
                        text="VOLVER AL INICIO"
                        classes="rounded-btn gradient-btn btn-contact"
                        style={{ padding: "11px 56px 11px 56px" }}
                    />
                </Link>
            </Content>
        </ThanksContainer>
    );
};

const vh = Math.max(
    document.documentElement.clientHeight || 0,
    window.innerHeight || 0
);

const ThanksContainer = styled.div`
    height: ${vh}px;
    position: relative;
    background: linear-gradient(
        180deg,
        #ffffff 0%,
        rgba(245, 244, 244, 0) 90.62%
    );
    z-index: 200;
    transition: all 1s ease-in-out;
    &::before {
        content: "";
        display: block;
        position: absolute;
        background-image: url("/static/media/vectorFondo.49ff0e73.svg");
        background-size: 100vw;
        opacity: 0.04;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        z-index: -1;
        [data-theme="dark"] & {
            opacity: 0.3;
        }
    }
    [data-theme="dark"] & {
        background: #202534;
    }
    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }
`;

const Logo = styled.div`
    position: absolute;
    background-image: url("/static/media/logo.192dbab5.svg");
    background-repeat: no-repeat;
    height: 85px;
    width: 400px;
    margin-top: 100px;
    margin-left: 12%;
    transition: all 1s ease-in-out;
    @media (max-width: 768px) {
        & {
            display: none;
        }
    }
    [data-theme="dark"] & {
        background-image: url("/static/media/logoDark.5239f919.svg");
    }
`;

const Content = styled.div`
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    @media (max-width: 768px) {
        & {
            width: 100%;
        }
    }
`;

const Image = styled.div`
    background-image: url("/static/media/Analysis-cuate 1.12989158.svg");
    background-repeat: no-repeat;
    background-size: contain;
    width: 274px;
    height: 260px;
    @media (max-width: 768px) {
        & {
            /* width: 200px;
            height: 200px; */
        }
    }
    [data-theme="dark"] & {
        /* background-image: url("/static/media/logoDark.5239f919.svg"); */
    }
`;

const Text = styled.p`
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #263238;
    margin: 40px auto;
    @media (max-width: 768px) {
        & {
            font-size: 13px;
            line-height: 19px;
            width: 90%;
            margin: 20px auto;
        }
    }
    [data-theme="dark"] & {
        color: white;
    }
`;

export default Thanks;
