import React, {useContext} from 'react'
import { LangContext } from "../../context/LangProvider";
import "./perfil.scss"
import Emprendedores from "./../../assets/img/Emprendedores.svg";
import Empresarios from "./../../assets/img/Empresarios.svg";
import Marketing from "./../../assets/img/Director de Marketing.svg";
import Ventas from "./../../assets/img/Director de Ventas.svg";

const Perfil = () => {
    const { fileTexts } = useContext(LangContext);

    return (
        <>
        <div className="profile">
            <div className="p1">
                <h2 className="p2">{fileTexts.profile.title}</h2>
                <p className="p3">{fileTexts.profile.subtitle}</p>
            </div>
            <div className="p1-2">
                <div className="p4">
                    <img src={Emprendedores} alt="Emprendedores" />
                    <h5 className="p8">{fileTexts.profile.customers.cone.type}</h5>
                    <p className="p12">{fileTexts.profile.customers.cone.phrase}</p>
                </div>
                <div className="p4">
                    <img src={Empresarios} alt="Empresarios" />
                    <h5 className="p8">{fileTexts.profile.customers.ctwo.type}</h5>
                    <p className="p12">{fileTexts.profile.customers.ctwo.phrase}</p>
                </div>
                <div className="p4">
                    <img src={Marketing} alt="Director de Marketing" />
                    <h5 className="p8">{fileTexts.profile.customers.cthree.type}</h5>
                    <p className="p12">{fileTexts.profile.customers.cthree.phrase}</p>
                </div>
                <div className="p4">
                    <img src={Ventas} alt="Director de Ventas" />
                    <h5 className="p8">{fileTexts.profile.customers.cfour.type}</h5>
                    <p className="p12">{fileTexts.profile.customers.cfour.phrase}</p>
                </div>
            </div>
            </div>
        </>
    )
}

export default Perfil;
