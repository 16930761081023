import React, { useState, useEffect, useRef } from "react";
import { HtmlInput, Icon, InputGroup, Warning, Success, Label, EmailInput, Loader } from "./form";
import axios from 'axios'
import { TailSpin } from 'react-loading-icons'
import { lighten } from "@material-ui/core";
import { ThemeContext } from "../../context/ThemeProvider";

const Input = ({ state, setState, icon, type, placeholder, regex, name, id }) => {
    const { actTheme } = React.useContext(ThemeContext);

    // const [validation, setValidation] = useState(null)

    // useEffect(() => {
    //     setState({ ...state, valid: validation })
    // }, [validation])

    // const validateOnComplete = val => {
    //     setState({ ...state, field: val, valid: "loading"})
    //     console.log(val)ailOk = await axios.get(`https://apps.emaillistverify.com/api/verifyEmail?secret=joF9ndL1w2nJxcbS35QS4&email=${val}`)
    //             setEmailVerified(emailOk.data)
    //             console.log(emailOk);
    //             if(emailOk.data === "ok"){
    //                 setValid
    //     const nose = async () => {
    //         try {
    //             let emation("true")
    //             }else{
    //                 setEmailVerified("No se pudo comprobar el correo, verifica que esté bien escrito")
    //                 setState({ ...state, valid: "false", error: [ state.error[0] ]})
    //                 setValidation("false")
    //             }
    //         } catch (error) {
    //             console.log(error);
    //         }
    //     }
    //     nose()
    // }

    const validate = (e, value) => {
        // validamos con api
        console.log(e, value);
        if(e){
            if(e?.target.value.length == 0){
                setState({ 
                    ...state,
                    valid: 'false',
                    error: [ state.error[0],  "Olvidaste completar este campo."] 
                })
            }else{ 
                if( name === "email"){
                    setState({ ...state, valid: "loading"})
                    if (regex) {
                        if(regex.test(state.field)){ 
                            const nose = async () => {
                                try {
                                    let emailOk = await axios.get(`https://apps.emaillistverify.com/api/verifyEmail?secret=joF9ndL1w2nJxcbS35QS4&email=${e.target.value}`)
                                    setEmailVerified(emailOk.data)
                                    console.log(emailOk);
                                    if(emailOk.data === "ok"){
                                        setState({ ...state, valid: "true" })
                                    }else{
                                        setEmailVerified("No se pudo comprobar el correo, verifica que esté bien escrito")
                                        setState({ ...state, valid: "false", error: [ state.error[0] ]})
                                    }
                                } catch (error) {
                                    console.log(error);
                                }
                            }
                            nose()
                        }else{
                            setState({ ...state, valid: "false"})
                            setEmailVerified("Comprueba que escribiste el correo completo")
                        }
                    }
                }else{
                    if (regex) {
                        if(regex.test(state.field)){
                            setState({ ...state, valid: "true" })
                        }else{
                            setState({ ...state, valid: "false", error: [ state.error[0] ]})
                        }
                    }
                }
            }
        } else {
            console.log(value);
            if (regex) {
                if(regex.test(value)){ 
                    const nose = async () => {
                        try {
                            let emailOk = await axios.get(`https://apps.emaillistverify.com/api/verifyEmail?secret=joF9ndL1w2nJxcbS35QS4&email=${value}`)
                            setEmailVerified(emailOk.data)
                            console.log(emailOk);
                            if(emailOk.data === "ok"){
                                setState({ ...state, field: value, valid: "true" })
                            }else{
                                setEmailVerified("No se pudo comprobar el correo, verifica que esté bien escrito")
                                setState({ ...state, field: value, valid: "false", error: [ state.error[0] ]})
                            }
                        } catch (error) {
                            console.log(error);
                        }
                    }
                    nose()
                }else{
                    setState({ ...state, valid: "false"})
                    setEmailVerified("Comprueba que escribiste el correo completo")
                }
            }
        }
    };

    const [emailVerified, setEmailVerified] = React.useState('')

    

    const onChangeHandler = (e, value) => {
        if(e){
            if (e.target.name === "ventas") {
                let str = e.target.value.replace(/,/g, '').replace(/\D/g, '').replace(/$/g, '');
                if(str !== ''){
                    e.target.value = '$' + parseFloat(str).toLocaleString('en-US', {
                        style: 'decimal',
                        maximumFractionDigits: 2
                    });
                }
                console.log(str);
            } else { 
                setState({ ...state, field: e.target.value });
            }
        } else {      
            setState({ ...state, field: value }, validate(false, value));
        }
    };

    const emailAutoCompleteRef = useRef();

    const styleOverrides = {
  
        /* Edit style for the suggestions "outer" container */
    
        container: {
          position: 'absolute',
          top: '40px',
          left: '40px'
        },
    
        /* Edit style for the suggestions overlay */
    
        // overlay: {
        //   backgroundColor: '#000'
        // },
    
        /* Edit style for the suggestions text */
    
        // text: {
    
        //   /* Main text */
    
        //   suggestion: {
        //     color: 'purple'
        //   },
    
        //   /* Highlighted/matched text */
    
        //   highlight: {
        //     color: 'blue'
        //   }
        // }
      };

      const onCompletion = (value) => {
        setState({ ...state, field: value, valid: "loading"}, validate(false, value))
      }

    return (
        <div>
            <InputGroup>
                { name === "ventas" ? (
                    <HtmlInput
                        type={type}
                        placeholder={placeholder}
                        onChange={validate}
                        onKeyUp={validate}
                        onBlur={onChangeHandler}
                        valid={state.valid}
                        name={name}
                        id={id}
                    />
                ) : name === "email" ? (
                    <EmailInput
                        ref={emailAutoCompleteRef}
                        onCompletion={value => onCompletion(value)}
                    >
                        <HtmlInput 
                            placeholder={placeholder}
                            onChange={onChangeHandler}
                            onBlur={validate}
                            valid={state.valid}
                            name={name}
                            className="form-control"
                            theme={actTheme}
                            onFocus={() => setState({ ...state, valid: "focused" })}
                            value={state.field} 
                            //onChange={(e) => setState({...state, field: e.target.value})} 
                            onKeyDown={(e) => emailAutoCompleteRef.current.check(e)} 
                        />
                    </EmailInput>
                ) : (
                    <HtmlInput
                        type={type}
                        placeholder={placeholder}
                        value={state.field}
                        onChange={onChangeHandler}
                        onKeyUp={validate}
                        onBlur={validate}
                        valid={state.valid}
                        name={name}
                        onFocus={() => setState({ ...state, valid: "focused" })}
                        theme={actTheme}
                    />
                )
                }
                <Icon type={name} valid={state.valid} />
                {
                    state.valid === "true" ?
                    <Success
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        valid={state.valid}
                    >
                        <path
                            d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18457 2.99721 7.13633 4.39828 5.49707C5.79935 3.85782 7.69279 2.71538 9.79619 2.24015C11.8996 1.76491 14.1003 1.98234 16.07 2.86"
                            stroke="#1DB954"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M22 4L12 14.01L9 11.01"
                            stroke="#1DB954"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </Success>
                    : state.valid === "loading" ? 
                    <Loader
                    >
                       <TailSpin stroke="#77216b" strokeOpacity="1" width="24px" height="24px" />
                    </Loader> 
                    : <Success
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    valid={state.valid}
                >
                    <path
                        d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18457 2.99721 7.13633 4.39828 5.49707C5.79935 3.85782 7.69279 2.71538 9.79619 2.24015C11.8996 1.76491 14.1003 1.98234 16.07 2.86"
                        stroke="#1DB954"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M22 4L12 14.01L9 11.01"
                        stroke="#1DB954"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </Success>
                }
            </InputGroup>
            <Warning valid={state.valid}>
                <ul>
                    {state.error.map( e => {
                        return (
                            <li>{e}</li>
                        )
                    })}
                </ul>
            </Warning>

        </div>
    );
};

export default Input;
