import React from "react";
import "./button.scss";

const Button = ({ style, text, classes, onClick, type, disabled }) => {
    return (
        <button
            style={style}
            type={type}
            className={classes}
            onClick={onClick}
            disabled={disabled}
        >
            {text}
        </button>
    );
};

export default Button;
