import React, { useContext } from "react";
import "./map.scss";
import { LangContext } from "../../context/LangProvider";

const Map = () => {
  const { fileTexts } = useContext(LangContext);
  return (
    <div className="map-component">
        <h4 className="distribution-text">{fileTexts.map.text}</h4>
    </div>
  );
};

export default Map;
