import React, { useEffect, useState, useRef } from "react";
import { useParams, Link } from "react-router-dom";
import { CopyBlock, dracula } from "react-code-blocks";
import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import ImageTool from "@editorjs/image";
import Embed from "@editorjs/embed";
import Code from "@editorjs/raw";
import Quote from "@editorjs/quote";
import InstagramEmbed from "react-instagram-embed";
import "./article.scss";
import defaultUser from "./../../assets/img/defaultUser.png";
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import download from 'downloadjs'
import moment from 'moment'
import 'moment/locale/es';
import Post from './Post'
import { useHistory, useLocation } from "react-router-dom";

import { firebase } from "../../firebase";
import { useDispatch, useSelector } from 'react-redux'
import { getArticleFieldsAction,
        getRelatedArticlesAction,
        getMoreRelatedArticlesAction,
        redirectToArticleAction
        } from './../../redux/articles'
import { persona } from "../../assets/icons";

const Article = ({ collectionData, firestore, user }) => {
    const { name } = useParams();
    const corrected = name.replace(/\porciento/g, "%").replace(/[-]/g, " ");
    
    console.log(corrected);
    const dispatch = useDispatch();

    const art = useSelector(store => store.articles.fields);

    const related = useSelector(store => store.articles.related);

    useEffect(() => {
        const getData = () => {
            dispatch(getArticleFieldsAction(corrected))
        }
        getData()
    }, [dispatch, corrected])

    useEffect(() => {
        const getData = () => {
            dispatch(getRelatedArticlesAction(art?.metadata?.tags[0].sys.id, art?.metadata?.tags[1].sys.id, art?.sys?.id))
        }
        getData()
    }, [dispatch, art])

    const moreRelatedArticles = () => {
        dispatch(getMoreRelatedArticlesAction(art?.metadata?.tags[0].sys.id, art?.metadata?.tags[1].sys.id))
    }
    
    const articleClickHandler = () =>{
        dispatch(redirectToArticleAction())
        myRefUp.current.scrollIntoView({ behavior: 'smooth' })  
    }
    
    const myRefUp = useRef(null);

    let history = useHistory();
    let location = useLocation();
    const goHome = () => {
        if(window.location.href.search("/tips/") !== -1 || window.location.href.search("/gracias") !== -1){
            location.state?.fromHome? history.goBack() : history.replace('/')
        }
    }

    // useEffect(() => {
    //     const setBlocksContent = () => {
    //         setBlocks(art.fields?.editordetips.content)
    //     }
    //     setBlocksContent()
    // }, [art])

    // const [article, setArticle] = React.useState({});
    // const [fecha, setFecha] = React.useState("");
    // const [temas, setTemas] = React.useState([]);
    const [blocks, setBlocks] = React.useState([]);
    // const [edit, setEdit] = React.useState(false);

    const [newPost, setNewPost] = useState({
        ultimaEdicion: firebase.firestore.FieldValue.serverTimestamp(),
        coleccion: null,
        temas: null,
        contenido: null,
        titulo: null,
        imagen: null,
    });

    // const [selectedFiles, setSelectedFiles] = useState([]);


    // const downloadSelection = () => {
    //     let zip = new JSZip();
    //     let documents = zip.folder('documentos');
    //     selectedFiles.forEach((archivo, i) => {
    //         // Fetch the image and parse the response stream as a blob
    //         const archivoBlob = fetch(archivo).then(response => { 
    //             console.log(response) 
    //             response.blob()
    //         });
    //         // create a new file from the blob object
    //         const archivoFile = new File([archivoBlob], "filename.mp4")
    //         documents.file('archivo'+i+'.mp4', archivoFile)
    //         console.log(archivoFile);
    //     });

    //     zip.generateAsync({type: "blob"}).then(content => FileSaver.saveAs(content, "IngenieriaDigital.zip"));
    // }

    const descargar = (url) => {
        download(url)
    }


    // false = mujer
    var persona = {
        nombre: "",
        nacionalidad: "",
        edad: null,
        genero: false,
    }

    console.log(
        persona
    );

    // * funciones para convertir en sentence case
    /* function firstLetterUpper(theString) {
        var newString = theString
            .toLowerCase()
            .replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (c) {
                return c.toUpperCase();
            });
        return newString;
    }

    function convertToSentenceCase() {
        var theString = document.testForm.theString.value;
        //alert(theString);
        var newString = firstLetterUpper(theString);
        //console.log("Converted: "+newString);
        document.getElementById("theString").value = newString;
    } */

    // // * Bloque propio para aceptar código
    // class htmlCode {
    //     static get toolbox() {
    //         return {
    //             title: "Html",
    //             icon:
    //                 '<svg width="17" height="15" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="currentColor" d="M278.9 511.5l-61-17.7c-6.4-1.8-10-8.5-8.2-14.9L346.2 8.7c1.8-6.4 8.5-10 14.9-8.2l61 17.7c6.4 1.8 10 8.5 8.2 14.9L293.8 503.3c-1.9 6.4-8.5 10.1-14.9 8.2zm-114-112.2l43.5-46.4c4.6-4.9 4.3-12.7-.8-17.2L117 256l90.6-79.7c5.1-4.5 5.5-12.3.8-17.2l-43.5-46.4c-4.5-4.8-12.1-5.1-17-.5L3.8 247.2c-5.1 4.7-5.1 12.8 0 17.5l144.1 135.1c4.9 4.6 12.5 4.4 17-.5zm327.2.6l144.1-135.1c5.1-4.7 5.1-12.8 0-17.5L492.1 112.1c-4.8-4.5-12.4-4.3-17 .5L431.6 159c-4.6 4.9-4.3 12.7.8 17.2L523 256l-90.6 79.7c-5.1 4.5-5.5 12.3-.8 17.2l43.5 46.4c4.5 4.9 12.1 5.1 17 .6z"></path></svg>',
    //         };
    //     }
    //     render() {
    //         return document.createElement("input");
    //     }

    //     save(blockContent) {
    //         return {
    //             html: blockContent.value,
    //         };
    //     }
    // }

    // // * Bloque propio para aceptar url Instagram
    // class instagramEmbed {
    //     static get toolbox() {
    //         return {
    //             title: "Instagram",
    //             icon:
    //                 '<svg width="17px" height="15px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path></svg>',
    //         };
    //     }
    //     render() {
    //         return document.createElement("input");
    //     }

    //     save(blockContent) {
    //         return {
    //             instagram: blockContent.value,
    //         };
    //     }
    // }

    // React.useEffect(() => {
    //     // const postRef = firestore.collection("articulos").doc(id);
    //     // postRef.onSnapshot((snap) => {
    //     //     const data = snap.data();
    //     //     if (data) {
    //     //         setArticle(data);
    //     //         setFecha(data.fechaCreacion.toDate().toDateString());
    //     //         setTemas(data.temas);
    //     //         setBlocks(data.contenido.blocks);
    //     //     }
    //     // });
    // }, []);

    // React.useEffect(() => {
    //     const editor = new EditorJS({
    //         /**
    //          * Id of Element that should contain the Editor
    //          */
    //         holder: "editorjs",
    //         /**
    //          * Available Tools list.
    //          * Pass Tool's class or Settings object for each Tool you want to use
    //          */
    //         placeholder:
    //             "Escribe aquí un párrafo o inserta un video de youtube",
    //         tools: {
    //             header: {
    //                 class: Header,
    //                 inlineToolbar: true,
    //                 config: {
    //                     placeholder: "Título",
    //                 },
    //             },
    //             embed: {
    //                 class: Embed,
    //                 config: {
    //                     services: {
    //                         youtube: true,
    //                         coub: true,
    //                         instagram: true,
    //                         twitter: true,
    //                     },
    //                 },
    //             },
    //             quote: {
    //                 class: Quote,
    //                 inlineToolbar: true,
    //                 shortcut: "CMD+SHIFT+O",
    //                 config: {
    //                     quotePlaceholder: "Escribe una cita",
    //                     captionPlaceholder: "Autor de la cita",
    //                 },
    //             },
    //             image: {
    //                 class: ImageTool,
    //                 config: {
    //                     uploader: {
    //                         async uploadByFile(file) {
    //                             var storageRef = firebase.storage().ref();
    //                             var imagesRef = storageRef
    //                                 // .child(title.title)
    //                                 .child("images/" + file.name);
    //                             var metadata = {
    //                                 contentType: "image/jpeg",
    //                             };
    //                             var uploadTask = await imagesRef.put(
    //                                 file,
    //                                 metadata
    //                             );
    //                             console.log(
    //                                 "Uploaded successfully!",
    //                                 uploadTask
    //                             );
    //                             const downloadURL = await uploadTask.ref.getDownloadURL();
    //                             console.log(downloadURL);
    //                             return {
    //                                 success: 1,
    //                                 file: {
    //                                     url: downloadURL,
    //                                 },
    //                             };
    //                         },
    //                     },
    //                     captionPlaceholder: "Pie de foto",
    //                 },
    //             },
    //             raw: Code,
    //             html: htmlCode,
    //             instagram: instagramEmbed,
    //         },
    //         data: {
    //             blocks: blocks,
    //         },
    //         onChange: () => {
    //             // editor.save().then((outputData) => {
    //             //     try {
    //             //         update(outputData);
    //             //     } catch (error) {
    //             //         console.log(error);
    //             //     }
    //             // });
    //         },
    //     });
    // }, [edit]);

    // if (blocks.length !== 0) {
    //     console.log(blocks[1].data.caption);
    // }

    // const enableEdit = () => {
    //     setEdit(!edit);
    // };

    // const deletePost = () => {
    //     let r = window.confirm("De verdad deseas eliminar!");
    //     if (r == true) {
    //         firestore
    //             .collection("articulos")
    //             .doc(id)
    //             .delete()
    //             .then(() => {
    //                 alert("Post eliminido correctamente!");
    //                 window.location.href = "//localhost:3000/#blog";
    //             });
    //     }
    // };

    moment.locale('es-mx');

    return (
        <>
            <header ref={myRefUp}>
                {/* <div className="logo" onClick={goHome}></div> */}
            </header>
            {
            // user ? (
            //     <div className="admin-opc">
            //         {edit ? (
            //             <i className="fas fa-save" onClick={enableEdit}></i>
            //         ) : (
            //             <i className="fas fa-edit" onClick={enableEdit}></i>
            //         )}

            //         {/* <i className="fas fa-trash" onClick={deletePost}></i> */}
            //     </div>
            // ) : null
            }
            
            <div className="article-container">
                {
                // edit ? (
                    //<div id="editorjs"></div>
                // ) :
                 art?.fields && (
                    <div className="article-wrapper">
                        <div className="article-title">
                            <h1>{art.fields.titulolargo}</h1>
                        </div>
                        <div className="article-autor-info">
                            <img
                                src={
                                    art.fields.autorarticulo.fields.autorfotoperfil.fields.file.url
                                        ? art.fields.autorarticulo.fields.autorfotoperfil.fields.file.url
                                        : defaultUser
                                }
                                alt={art.fields.autorarticulo.fields.autornombre}
                            />
                        
                            <div>
                                <p>
                                    <span className="pre-text">Por </span>
                                    {art.fields.autorarticulo.fields.autornombre}
                                </p>
                                <p>
                                    <span className="pre-text">
                                        Última edición:{" "}
                                    </span>
                                    {moment(art.sys.updatedAt, moment.ISO_8601).format('LL')}
                                </p>
                            </div>
                        </div>
                        <div className="tags">
                            {art.fields.temas.map((tema, index) => (
                                <div className="tag" key={index}>
                                    {tema}
                                </div>
                            ))}
                            {/* <div className="tag">{temas[0]}</div>
                        <div className="tag">{temas[1]}</div> */}
                        </div>
                        <div className="image-article">
                            <img
                                src={art.fields.imagenprincipal.fields.file.url}
                                alt={art.fields.titulocorto
                                }
                            />
                            {/* <hr />
                            <p>
                                <span>Pie de foto. </span>
                                {blocks.length !== 0
                                    ? blocks[1].data.caption
                                    : null}
                            </p> */}
                        </div>
                        {/* replace(
                                            /nbsp;/g,
                                            "",
                                            /&lt;/g,
                                            "<",
                                            /&gt;/g,
                                            ">",
                                            /&amp;/g,
                                            "&"
                                        ) */}
                        {/* {blocks.map((block, i) => {
                            console.log(block.type);
                            switch (block.type) {
                                case "paragraph":
                                    return (
                                        <div>
                                            <p
                                                className={block.type}
                                                // dangerouslySetInnerHTML={{
                                                //     __html: block.data.text
                                                //         .replace(/&lt;/g, "<")
                                                //         .replace(/&gt;/g, ">")
                                                //         .replace(/&amp;/g, "&")
                                                //         .replace(/&quot;/g, `"`),
                                                // }}
                                            >
                                                {block.data.text}
                                            </p>
                                        </div>
                                    );
                                case "quote":
                                    return (
                                        <q className={block.type}>
                                            {block.data.text}
                                        </q>
                                    );
                                case "raw":
                                    return (
                                        <div className={block.type}>
                                            <CopyBlock
                                                className={block.type}
                                                text={block.data.html}
                                                language={"html"}
                                                showLineNumbers={true}
                                                theme={dracula}
                                                codeBlock
                                                wrapLines
                                            />
                                        </div>
                                    );
                                case "embed":
                                    return (
                                        <div className={block.type}>
                                            <iframe
                                                width="100%"
                                                height="500"
                                                src={block.data.embed}
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullscreen
                                            ></iframe>
                                            <hr />
                                            <p>
                                                <span>Pie de video. </span>
                                                {blocks.length !== 0
                                                    ? block.data.caption
                                                    : null}
                                            </p>
                                        </div>
                                    );
                                case "instagram":
                                    return (
                                        <InstagramEmbed
                                            url={block.data.text}
                                            clientAccessToken="123|456"
                                            maxWidth={320}
                                            hideCaption={false}
                                            containerTagName="div"
                                            protocol=""
                                            injectScript
                                            onLoading={() => {}}
                                            onSuccess={() => {}}
                                            onAfterRender={() => {}}
                                            onFailure={() => {}}
                                        />
                                    );
                                default:
                                    break;
                            }
                        })} */}
                        <iframe
                            width="100%"
                            height="400"
                            src={art.fields.videoarticulo}
                            frameBorder="0"
                            allowFullScreen="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        ></iframe>
                        {
                            art.fields?.descargablevideo &&
                        <div className="descarga" onClick={() => descargar(art.fields?.descargablevideo.fields.file.url.replace(/\/\//g, "https://"))}>
                            <i className="fas fa-film"></i>
                            <span>Descargar video</span>
                        </div>
                        }
                        {
                        art.fields.descargableexcel &&
                        <div className="descarga" onClick={() => descargar(art.fields?.descargableexcel.fields.file.url.replace(/\/\//g, "https://"))}>
                            <i className="fas fa-file"></i>
                            <span>Descargar Excel</span>
                        </div>
                        }
                        {
                        art.fields.descargablepresentacion &&
                        <div className="descarga" onClick={() => descargar(art.fields?.descargablepresentacion.fields.file.url.replace(/\/\//g, "https://"))}>
                            <i className="fas fa-video"></i>
                            <span>Descargar presentación</span>
                        </div>
                        }
                        {
                        art.fields.descargableaudio &&
                        <div className="descarga" onClick={() => descargar(art.fields?.descargableaudio.fields.file.url.replace(/\/\//g, "https://"))}>
                            <i className="fas fa-headphones"></i>
                            <span>Descargar audio</span>
                        </div>
                        }
                        
                        {art.fields?.editordetips.content.map((block, i) => {
                            switch (block.nodeType) {
                                case "paragraph":
                                    return (
                                        <div key={i}>
                                            <p
                                                className="paragraph"
                                                // dangerouslySetInnerHTML={{
                                                //     __html: block.data.text
                                                //         .replace(/&lt;/g, "<")
                                                //         .replace(/&gt;/g, ">")
                                                //         .replace(/&amp;/g, "&")
                                                //         .replace(/&quot;/g, `"`),
                                                // }}
                                            >
                                                {
                                                    block.content.map(content => content.value)
                                                }
                                            </p>
                                        </div>
                                    );
                                case "blockquote":
                                    return (
                                        <q className="quote">
                                            {block.content[0].content[0].value}
                                        </q>
                                    );
                                case "raw":
                                    return (
                                        <div className={block.type} key={i}>
                                            <CopyBlock
                                                className={block.type}
                                                text={block.data.html}
                                                language={"html"}
                                                showLineNumbers={true}
                                                theme={dracula}
                                                codeBlock
                                                wrapLines
                                            />
                                        </div>
                                    );
                                case "embed":
                                    return (
                                        <div className={block.type} key={i}>
                                            <iframe
                                                width="100%"
                                                height="500"
                                                src={block.data.embed}
                                                frameBorder="0"
                                                allowFullScreen="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            ></iframe>
                                            <hr />
                                            <p>
                                                <span>Pie de video. </span>
                                                {blocks.length !== 0
                                                    ? block.data.caption
                                                    : null}
                                            </p>
                                        </div>
                                    );
                                case "ordered-list":
                                    console.log(block.content[0].content[0].content[0].value);
                                    return (
                                        <ol className="blog-list" key={i}>
                                            {block.content.map((content, i) => <li key={i}>{content.content[0].content[0].value}</li>)} 

                                        </ol>
                                    );
                                default:
                                    break;
                            }
                        })}
                          
                    </div>
                )}
                <div className="blog-wrapper-related">
                    <div className="blog-container-related">
                        <div className="blog-title-related">
                            <p>También te puede interesar</p>
                        </div>
                        <div className="posts-container">
                            {related.length != 0 &&
                                related.map((article) => (
                                    <Link
                                        to={{
                                            pathname: `/tips/${article.fields.titulocorto.replace(/[%]/g, "porciento").replace(/\+|%20|\s/g, "-")}`,
                                            state: { fromHome: false }
                                        }}
                                        key={article.sys.id}
                                        className="link-card"
                                        onClick={articleClickHandler}
                                    >
                                        <Post
                                            key={article.sys.id}
                                            title={article.fields.titulocorto}
                                            img={article.fields.imagenprincipal?.fields.file.url}
                                            //collection={article.coleccion}
                                            topics={article.fields.temas}
                                            icons={article.fields}
                                            shareLink={`https://ingenieriadigital.mx/tips/${article.fields.titulocorto.replace(/[%]/g, "porciento").replace(/\+|%20|\s/g, "-")}`}
                                            time={article.fields.TiempoDeLectura}
                                        />
                                    </Link>
                                ))
                            }
                        </div>
                        <div className="load-more-container" onClick={moreRelatedArticles}>
                            <div className="circle">
                                <i className="fas fa-plus"></i>
                            </div>
                            <p>Ver más</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Article;
