import { client } from './../components/blog/client'

//  Constants
const initData = {
    array: [],
    id: null,
    name: null,
    fields: {},
    related: [],
    limit: 4,
}

// -------------------------------Types-------------------------------------
const GET_ARTICLES_SUCCEED = 'GET_ARTICLES_SUCCEED';
const REDIRECT_TO_ARTICLE = 'REDIRECT_TO_ARTICLE';
const GET_ARTICLE_FIELDS = 'GET_ARTICLE_FIELDS';
const GET_RELATED_ARTICLES = 'GET_RELATED_ARTICLES';
const GET_MORE_RELATED_ARTICLES = 'GET_MORE_RELATED_ARTICLES';

// -------------------------------Reducer-----------------------------------
export default function articleReducer(state = initData, action){
    switch (action.type) {
        case GET_ARTICLES_SUCCEED:
            return {...state, array: action.payload }
        case REDIRECT_TO_ARTICLE:
            return {...state, id: action.payload.id, fields: action.payload.fields }
        case GET_ARTICLE_FIELDS:
            return {...state, id: action.payload.id, name: action.payload.name, fields: action.payload.fields}
        case GET_RELATED_ARTICLES:
            return {...state, related: action.payload.related}
        case GET_MORE_RELATED_ARTICLES:
            return {...state, related: action.payload.related, limit: action.payload.limit}
        default:
            return state
    }
}

// -------------------------------Actions-------------------------------------

export const getArticlesAction = () => async (dispatch) => {
    client.getEntries({
        content_type: "tipsingenieradigital",
        order: "fields.order"
      }).then(response => {
          dispatch({
              type: GET_ARTICLES_SUCCEED,
              payload: response.items
          })
    }).catch(console.error)
}

export const redirectToArticleAction = (id) => async (dispatch) => {
    client.getEntry(id).then(response => {
          dispatch({
              type: REDIRECT_TO_ARTICLE,
              payload: {
                  id: id,
                  fields: response.fields
              }
          })
    }).catch(console.error)
}

export const getArticleFieldsAction = (name) => async (dispatch) => {
    client.getEntries({
        content_type: 'tipsingenieradigital',
        'fields.titulocorto[match]': name
      }).then(response => {
        console.log(response);
        dispatch({
            type: GET_ARTICLE_FIELDS,
            payload: {
                id: response.items[0].sys.id,
                name: name,
                fields: response.items[0]
            }
        })
  }).catch(console.error)
}

export const getRelatedArticlesAction = (tag1, tag2, id) => async (dispatch, getState) => {
    const baselimit = getState().articles.limit;
    client.getEntries({
        content_type: 'tipsingenieradigital',
        'metadata.tags.sys.id[all]': tag1 + "," + tag2 ,
        limit: baselimit,
        'sys.id[ne]': id
      }).then(response => {
        console.log(response);
        dispatch({
            type: GET_RELATED_ARTICLES,
            payload: {
                related: response.items
            }
        })
  }).catch(console.error)
}

export const getMoreRelatedArticlesAction = (tag) => async (dispatch, getState) => {

    const newLimit = getState().articles.limit + 4;

    client.getEntries({
        content_type: 'tipsingenieradigital',
        'metadata.tags.sys.id[all]': tag,
        limit: newLimit
      }).then(response => {
        console.log(response);
        dispatch({
            type: GET_MORE_RELATED_ARTICLES,
            payload: {
                related: response.items,
                limit: newLimit
            }
        })
  }).catch(console.error)
}