import React, { useState, lazy, Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import * as Mousetrap from "mousetrap";
import ThemeProvider from "./context/ThemeProvider";
import LangProvider from "./context/LangProvider";
import Header from "./components/header/Header";
import Team from "./components/team/Team";
import Perfil from "./components/perfil/Perfil";
import Login from "./components/login/Login";
import Article from "./components/blog/Article";
import Footer from "./components/footer/Footer";
import Nav from "./components/header/Nav";
import Form from "./components/contact/Formulario";
import Thanks from "./components/thanks/Thanks";
import Bubble from "./components/bubble/Bubble";
import Map from "./components/map/Map";

import { firebase } from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { Provider } from "react-redux";
import generateStore from "./redux/store";
import { useRef } from "react";
import Purpose from "./components/purpose/Purpose";
const Portfolio = lazy(() => import("./components/guide/Portfolio"));
const Us = lazy(() => import("./components/us/Us"));
const Video = lazy(() => import("./components/video/Video"));
const Process = lazy(() => import("./components/process/Process"));
const Clients = lazy(() => import("./components/clients/Clients"));
// const Blog = lazy(() => import("./components/blog/Blog"));

function App() {
  const [loading, setloading] = useState(true);
  const [open, setOpen] = useState(false);
  const [panel, setPanel] = useState(false);

  const auth = firebase.auth();
  const firestore = firebase.firestore();
  const [user] = useAuthState(auth);

  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    auth.signInWithPopup(provider);
  };

  document.addEventListener("DOMContentLoaded", () => {
    setloading(false);
  });

  const login = () => {
    setOpen(!open);
  };

  const showPanel = () => {
    setPanel(true);
    const body = document.getElementsByTagName("body")[0];
    body.classList.add("unscroll");
  };

  const hidePanel = () => {
    setPanel(false);
    const body = document.getElementsByTagName("body")[0];
    body.classList.remove("unscroll");
  };

  Mousetrap.bind(["command+l", "ctrl+l"], function (e) {
    login();
    return false;
  });
  const store = generateStore();
  const processRef = useRef(null);
  const myRef = useRef(null);
  const executeScroll = () => {
    window.scrollTo({
      top: myRef.current.offsetTop + 30,
      behavior: "smooth",
    });
  };

  // myRef.current.scrollIntoView({ behavior: 'smooth' })
  const executeProccessScroll = () => {
    window.scrollTo({
      top: processRef.current.offsetTop - 75,
      behavior: "smooth",
    });
  };

  // processRef.current.scrollIntoView({ behavior: 'smooth' })

  return (
    <>
      {loading ? null : (
        <LangProvider>
          <ThemeProvider>
            <Provider store={store}>
              <Router>
                <Nav scroll={executeScroll} />
                <Switch>
                  {/* <Route path="/new-blog" component={component} /> */}
                  {/* <Route
                                        path="/create-new-post"
                                        component={AdminPanel}
                                    /> */}
                  <Route path="/" exact>
                    <Header scroll={executeScroll} />
                    <Suspense fallback={<p>Cargando...</p>}>
                      <Us />
                    </Suspense>
                    <Suspense fallback={<p>Cargando...</p>}>
                      <Video scroll={executeProccessScroll} />
                    </Suspense>
                    <Suspense fallback={<p>Cargando...</p>}>
                      <Process propRef={processRef} />
                    </Suspense>
                    <Suspense fallback={<p>Cargando...</p>}>
                      <Portfolio />
                    </Suspense>
                    <Suspense fallback={<p>Cargando...</p>}>
                      <Clients />
                    </Suspense>
                    <Map />
                    <Team />
                    <Perfil />
                    <Form propRef={myRef} />
                    <Purpose />
                    {/* <Suspense fallback={<p>Cargando...</p>}>
                      <Blog
                        logged={user ? true : null}
                        showPanel={() => showPanel()}
                        firestore={firestore}
                        collectionData={useCollectionData}
                      />
                    </Suspense> */}
                    <Login
                      open={open}
                      close={() => setOpen(false)}
                      signIn={() => signInWithGoogle()}
                      user={user}
                      auth={auth}
                      photoURL={user ? auth.currentUser.photoURL : null}
                      logged={user ? "logged" : null}
                      uid={user ? auth.currentUser.uid : null}
                    />
                    {/* <AdminPanel
                                            open={panel}
                                            close={() => hidePanel()}
                                            logged={user}
                                            firebase={firebase}
                                            firestore={firestore}
                                            auth={auth}
                                        /> */}
                    <Bubble />
                  </Route>
                  <Route path="/formulario" exact>
                    <Form />
                  </Route>
                  <Route path="/gracias" exact>
                    <Thanks />
                  </Route>
                  <Route path="/tips/:name" exact>
                    <Article
                      firestore={firestore}
                      collectionData={useCollectionData}
                      user={user}
                    />
                  </Route>
                </Switch>
                <Footer />
              </Router>
            </Provider>
          </ThemeProvider>
        </LangProvider>
      )}
    </>
  );
}

export default App;
