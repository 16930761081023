import React from "react";
import "./team.scss";
import Teamcard from "./Teamcard";
import shortid from "short-id";

const Team = () => {
    const team = [
        {
            ename: "Clarissa Fajardo",
            ejob: "Marketing expert",
            url: "/Team/clarissa.svg",
        },
        {
            ename: "Christian Acosta",
            ejob: "Fullstack programmer",
            url: "/Team/christian.svg",
        },
        {
            ename: "Yudiel Fajardo",
            ejob: "Account strategist",
            url: "/Team/yudiel.svg",
        },
        {
            ename: "Sergio Aguilera",
            ejob: "Server manager",
            url: "/Team/sergio.svg",
        },
        {
            ename: "Cecilia Lugo",
            ejob: "Accounting assistant",
            url: "/Team/gloria.svg",
        },
        {
            ename: "Alex Valencia",
            ejob: "Backend programmer",
            url: "/Team/alejandro.svg",
        },
        {
            ename: "Lilian Marín",
            ejob: "Frontend programmer",
            url: "/Team/lilian.svg",
        },
        {
            ename: "Rita Reyes",
            ejob: "Public accountant",
            url: "/Team/rita.svg",
        },
        {
            ename: "Ricardo Reyero",
            ejob: "Digital strategist",
            url: "/Team/ricardo.svg",
        },
    ];

    return (
        <div className="team-container">
            <div className="title">
                <h2>El equipo de Ingeniería digital</h2>
            </div>
            <div className="team">
                {team.map((i) => (
                    <Teamcard
                        name={i.ename}
                        charge={i.ejob}
                        picture={i.url}
                        key={shortid.generate()}
                    />
                ))}
            </div>
        </div>
    );
};

export default Team;
