import React, { useState } from "react";
import "./post.scss";

const Post = ({ title, img, collection, topics, icons, shareLink, toYT, time}) => {

    const [ tooltipText, setTooltipText] = useState('Copiar link al portapapeles')
    
    const copy = (e) => {
        e.preventDefault()
        navigator.clipboard.writeText(shareLink)
        setTooltipText('Link copiado')
        setTimeout(() => { setTooltipText('Copiar link al portapapeles') }, 1500);
    }

    const resetText = () => {
        setTooltipText('Copiar link al portapapeles')
    }

    return (
        <div className="card">
            <div
                className="gradient"
                style={{
                    backgroundImage: `linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #2A2FAB 100%), url(${img})`,
                }}
            ></div>
                <div className="share-container" onClick={copy} onBlur={resetText}>
                    <div className="tooltip" onBlur={resetText}>
                        <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 14.08C14.24 14.08 13.56 14.38 13.04 14.85L5.91 10.7C5.96 10.47 6 10.24 6 10C6 9.76 5.96 9.53 5.91 9.3L12.96 5.19C13.5 5.69 14.21 6 15 6C16.66 6 18 4.66 18 3C18 1.34 16.66 0 15 0C13.34 0 12 1.34 12 3C12 3.24 12.04 3.47 12.09 3.7L5.04 7.81C4.5 7.31 3.79 7 3 7C1.34 7 0 8.34 0 10C0 11.66 1.34 13 3 13C3.79 13 4.5 12.69 5.04 12.19L12.16 16.35C12.11 16.56 12.08 16.78 12.08 17C12.08 18.61 13.39 19.92 15 19.92C16.61 19.92 17.92 18.61 17.92 17C17.92 15.39 16.61 14.08 15 14.08Z" fill="white"/>
                        </svg>
                        <span className="tooltiptext">{tooltipText}</span>
                    </div>
                </div>
                {/* <div className="watch-on-youtube" onClick={watchOnYT}>
                    <svg width="24" height="17" viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M22.5396 3.42C22.4208 2.94541 22.1789 2.51057 21.8382 2.15941C21.4976 1.80824 21.0703 1.55318 20.5996 1.42C18.8796 1 11.9996 1 11.9996 1C11.9996 1 5.1196 1 3.3996 1.46C2.92884 1.59318 2.50157 1.84824 2.16094 2.19941C1.82031 2.55057 1.57838 2.98541 1.4596 3.46C1.14481 5.20556 0.990831 6.97631 0.999595 8.75C0.988374 10.537 1.14236 12.3213 1.4596 14.08C1.59055 14.5398 1.8379 14.9581 2.17774 15.2945C2.51758 15.6308 2.93842 15.8738 3.3996 16C5.1196 16.46 11.9996 16.46 11.9996 16.46C11.9996 16.46 18.8796 16.46 20.5996 16C21.0703 15.8668 21.4976 15.6118 21.8382 15.2606C22.1789 14.9094 22.4208 14.4746 22.5396 14C22.852 12.2676 23.0059 10.5103 22.9996 8.75C23.0108 6.96295 22.8568 5.1787 22.5396 3.42V3.42Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M9.75 12.02L15.5 8.74998L9.75 5.47998V12.02Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <span>Ver en YouTube</span>
                </div> */}
                <div className="watch-on-youtube">
                    <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11 21.2681C16.5228 21.2681 21 16.7909 21 11.2681C21 5.74522 16.5228 1.26807 11 1.26807C5.47715 1.26807 1 5.74522 1 11.2681C1 16.7909 5.47715 21.2681 11 21.2681Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M11 5.26807V11.2681L15 13.2681" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <span>:{` ${time} `}min.</span>
                </div>
                <div className="article-data-container">
                    <div className="title">{title}</div>
                    <div className="bottom">
                        <div className="tags">
                            <div className="tag">{topics[0]}</div>
                            <div className="tag">{topics[1]}</div>
                        </div>
                        <div className="icons">
                            {/* {icons.map((element, index) => {
                                switch (element.type) {
                                    case "image":
                                        return (
                                            <i
                                                className="fas fa-file-image"
                                                key={index}
                                            ></i>
                                        );
                                    case "embed":
                                        return (
                                            <i
                                                className="fas fa-file-video"
                                                key={index}
                                            ></i>
                                        );
                                    case "raw":
                                        return (
                                            <i
                                                className="fas fa-code"
                                                key={index}
                                            ></i>
                                        );

                                    default:
                                        break;
                                }
                            })} */}
                            {/* 
                            <i className="fas fa-wrench"></i>
                            <i className="fas fa-download"></i> */}
                        </div>
                    </div>
                </div>
            
        </div>
    );
};

export default Post;
